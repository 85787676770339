import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/pageTemplate.js";
import Note from "../../components/text-decorations/note";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`While working at coiled, I came across the need to install conda in a docker image that didn't come pre-packed with conda.  When you create a `}<a parentName="p" {...{
        "href": "https://docs.coiled.io/user_guide/software_environment.html"
      }}>{`software environment`}</a>{` in coiled, we will create a new image based on one of the two:`}</p>
    <ul>
      <li parentName="ul">{`Your image if provided by the `}<inlineCode parentName="li">{`container`}</inlineCode>{` argument`}</li>
      <li parentName="ul">{`Our base image`}</li>
    </ul>
    <p>{`In this software environment, we install all the dependencies you specify with the command `}<inlineCode parentName="p">{`coiled.create_software_environment`}</inlineCode>{`. If we provide an image that doesn't have conda installed, then creating the software environment will fail if we try to install a dependency with conda. For example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`import coiled

coiled.create_software_environment(name="conda-test", conda=["dask"])

`}</code></pre>
    <h2 {...{
      "id": "installing-conda-in-docker",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#installing-conda-in-docker",
        "aria-label": "installing conda in docker permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Installing conda in docker`}</h2>
    <p>{`Let's see how you can install conda into a docker image. We will need to create a new `}<inlineCode parentName="p">{`Dockerfile`}</inlineCode>{` and decide which python version we will use.  Then we need to go to the `}<a parentName="p" {...{
        "href": "https://docs.conda.io/en/latest/miniconda.html"
      }}>{`miniconda installation page`}</a>{` and choose one of the Linux installers for your python version.`}</p>
    <Note mdxType="Note">
    <p>In this article, I will use the Python 3.8 installer, which has the name <code>Miniconda3-latest-Linux-x86_64.sh</code></p>
    </Note>
    <p>{`Let's go ahead and create our Dockerfile. I will add as many commands inside a single `}<inlineCode parentName="p">{`RUN`}</inlineCode>{` command because that makes the final image smaller.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-Dockerfile"
      }}>{`FROM <your image without conda>

# Install base utilities
RUN apt-get update \\
    && apt-get install -y build-essential \\
    && apt-get install -y wget \\
    && apt-get clean \\
    && rm -rf /var/lib/apt/lists/*

# Install miniconda
ENV CONDA_DIR /opt/conda
RUN wget --quiet https://repo.anaconda.com/miniconda/Miniconda3-latest-Linux-x86_64.sh -O ~/miniconda.sh && \\
    /bin/bash ~/miniconda.sh -b -p /opt/conda

# Put conda in path so we can use conda activate
ENV PATH=$CONDA_DIR/bin:$PATH

`}</code></pre>
    <p>{`We can now build this image. This image will have all the things your original image has plus conda, allowing coiled to install the dependencies without problems.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      